<!-- This file represents the side associated with each eye on the preop data page (one side is the OS eye, and the other is the OD side) -->
<template>
    <div
        :id="side"
        class="pb-2 eye-side-container"
        :class="{pointer: !activeEditing}"
        ref="container"
        @click="!activeEditing && !oppositeEyeDataSet.monocular && setActiveEye(side)"
    >
        <div class="d-flex flex-column flex-sm-row preop-form-container">
            <div v-if="oppositeEyeDataSet.monocular" class="monocular-overlay">
                <p>
                    <span class="bg-white p-2 rounded">{{ t('preop_Monocular') }}</span>
                </p>
            </div>

            <div
                id="leftColContainer"
                class="d-flex flex-column justify-content-between px-2 mb-3 mr-3"
            >
                <div class="d-flex align-items-center flex-column">
                    <h1
                        class="heavy text-center mb-3"
                        :class="{'text-gray-darker': !activeEditing}"
                    >
                        {{ side }}
                    </h1>
                    <svg
                        class="eye-icons"
                        width="58"
                        height="26"
                        viewBox="0 0 58 26"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g :class="{active: !isOS}">
                            <path
                                d="M12.8082 6.53955C7.9806 6.53955 3.85784 9.54231 2.1875 13.7809C3.85784 18.0195 7.9806 21.0223 12.8082 21.0223C17.6358 21.0223 21.7585 18.0195 23.4289 13.7809C21.7585 9.54231 17.6358 6.53955 12.8082 6.53955ZM12.8082 18.6085C10.1434 18.6085 7.9806 16.4458 7.9806 13.7809C7.9806 11.1161 10.1434 8.95334 12.8082 8.95334C15.473 8.95334 17.6358 11.1161 17.6358 13.7809C17.6358 16.4458 15.473 18.6085 12.8082 18.6085ZM12.8082 10.8844C11.2054 10.8844 9.91164 12.1782 9.91164 13.7809C9.91164 15.3837 11.2054 16.6775 12.8082 16.6775C14.4109 16.6775 15.7047 15.3837 15.7047 13.7809C15.7047 12.1782 14.4109 10.8844 12.8082 10.8844Z"
                            />
                            <path
                                d="M12.8714 0.494629C14.5827 0.494629 16.2259 0.723617 17.8009 1.18159C19.3759 1.6472 20.8071 2.33799 22.0943 3.25394C23.3816 4.16989 24.2903 5.12019 24.8203 6.10484L23.4346 6.53992C22.5865 5.42551 21.2917 4.50956 19.5501 3.79206C17.8085 3.0822 15.8586 2.69292 13.7006 2.62422L12.7351 2.61277C9.81227 2.61277 7.27559 3.15089 5.12509 4.22714C3.83782 4.87594 2.83072 5.64687 2.1038 6.53992L0.820312 6.10484C1.38065 5.08966 2.3196 4.12028 3.63716 3.19669C6.2117 1.39532 9.28979 0.494629 12.8714 0.494629Z"
                            />
                        </g>

                        <g :class="{active: isOS}">
                            <path
                                d="M45.6353 6.53955C40.8078 6.53955 36.685 9.54231 35.0146 13.7809C36.685 18.0195 40.8078 21.0223 45.6353 21.0223C50.4629 21.0223 54.5857 18.0195 56.256 13.7809C54.5857 9.54231 50.4629 6.53955 45.6353 6.53955ZM45.6353 18.6085C42.9705 18.6085 40.8078 16.4458 40.8078 13.7809C40.8078 11.1161 42.9705 8.95334 45.6353 8.95334C48.3002 8.95334 50.4629 11.1161 50.4629 13.7809C50.4629 16.4458 48.3002 18.6085 45.6353 18.6085ZM45.6353 10.8844C44.0326 10.8844 42.7388 12.1782 42.7388 13.7809C42.7388 15.3837 44.0326 16.6775 45.6353 16.6775C47.2381 16.6775 48.5319 15.3837 48.5319 13.7809C48.5319 12.1782 47.2381 10.8844 45.6353 10.8844Z"
                            />
                            <path
                                d="M45.8714 0.494629C47.5827 0.494629 49.2259 0.723617 50.8009 1.18159C52.3759 1.6472 53.8071 2.33799 55.0943 3.25394C56.3816 4.16989 57.2903 5.12019 57.8203 6.10484L56.4346 6.53992C55.5865 5.42551 54.2917 4.50956 52.5501 3.79206C50.8085 3.0822 48.8586 2.69292 46.7006 2.62422L45.7351 2.61277C42.8123 2.61277 40.2756 3.15089 38.1251 4.22714C36.8378 4.87594 35.8307 5.64687 35.1038 6.53992L33.8203 6.10484C34.3807 5.08966 35.3196 4.12028 36.6372 3.19669C39.2117 1.39532 42.2898 0.494629 45.8714 0.494629Z"
                            />
                        </g>
                    </svg>
                    <b-button center @click="reset()" v-if="activeEditing"
                        >{{ t('preop_Reset') }} {{ side }}</b-button
                    >
                </div>
            </div>
            <b-row class="flex-basis-100">
                <b-col lg="7" class="px-3 px-md-0">
                    <!-- REFRACTION -->
                    <b-card
                        body-class="pt-2"
                        class="refraction-card mb-4 mb-lg-2"
                        :class="{expanded: expanded, ['py-2 py-lg-0']: !expanded}"
                    >
                        <div
                            class="d-flex justify-content-between align-items-center mb-2 min-h-24px"
                        >
                            <div
                                class="text-caption heavy text-gray"
                                :class="[expanded ? 'text-primary' : 'text-gray']"
                            >
                                <span
                                    :class="[activeEditing ? 'text-primary' : 'text-gray-darker']"
                                >
                                    {{ t('preop_Refraction') }}:
                                </span>
                                <span
                                    :class="{
                                        'active-refraction-type':
                                            activeRefractionType === RefractionTypes.MANIFEST,
                                    }"
                                >
                                    {{ t('preop_Manifest') }}</span
                                >
                                <div class="d-inline" v-if="!expanded">
                                    <template v-if="region === ZoneCodes.US"> | </template>
                                    <span
                                        :class="{
                                            'active-refraction-type':
                                                activeRefractionType === RefractionTypes.CYCLO,
                                        }"
                                        v-if="region === ZoneCodes.US"
                                    >
                                        {{ t('preop_Cycloplegic') }}</span
                                    >
                                    |
                                    <span
                                        :class="{
                                            'active-refraction-type':
                                                activeRefractionType === RefractionTypes.CL,
                                        }"
                                    >
                                        {{ t('preop_CLOVERREF') }}</span
                                    >
                                </div>
                            </div>

                            <b-button
                                :class="{'hidden-element': !activeEditing}"
                                :disabled="disableChangingRefractionType"
                                @click="toggleExpanded"
                                size="sm"
                                variant="primary"
                                class="pre-op-expand-button text-small heavy text-uppercase mx-1"
                            >
                                {{ expanded ? t('preop_Collapse') : t('preop_Expand') }}
                            </b-button>
                        </div>

                        <!-- MANIFEST -->
                        <div
                            class="d-flex preop-input-container"
                            :class="{'mb-2': expanded}"
                            v-if="activeRefractionType === RefractionTypes.MANIFEST || expanded"
                        >
                            <b-form-radio
                                v-if="expanded"
                                :value="true"
                                :checked="dataSet.refractionInfo[RefractionTypes.MANIFEST].active"
                                :disabled="disableChangingRefractionType"
                                @change="
                                    (value) =>
                                        setActiveRefractionType(value, RefractionTypes.MANIFEST)
                                "
                                class="mt-2"
                            ></b-form-radio>

                            <!-- MANIFEST - SPHERE -->
                            <b-form-group class="preop-input">
                                <b-form-input
                                    :id="'input-m-sphere-' + this.side"
                                    v-model="
                                        $v.dataSet.refractionInfo[RefractionTypes.MANIFEST].sphere
                                            .$model
                                    "
                                    :class="
                                        inputClasses(
                                            `refractionInfo[${RefractionTypes.MANIFEST}].sphere`
                                        )
                                    "
                                    :state="
                                        validateState(
                                            `refractionInfo[${RefractionTypes.MANIFEST}].sphere`
                                        )
                                    "
                                    :formatter="formatInput"
                                    lazy
                                    :plaintext="!activeEditing"
                                    :disabled="disableRefractionTypeInput(RefractionTypes.MANIFEST)"
                                    autocomplete="off"
                                    @focus="onFocus('sphere', RefractionTypes.MANIFEST)"
                                    @blur="onBlur"
                                ></b-form-input>
                                <label
                                    :for="'input-m-sphere-' + this.side"
                                    class="text-uppercase text-caption heavy text-gray-dark w-100 text-center text-nowrap"
                                >
                                    {{ t('preopreport_Sphere') }}
                                </label>
                                <span class="input-units">D</span>
                                <b-tooltip
                                    custom-class="t-20"
                                    triggers="focus"
                                    :target="'input-m-sphere-' + this.side"
                                    placement="bottom"
                                >
                                    {{ tooltipText('sphere', ' D') }}
                                    <b-icon-check class="text-success ml-2" scale="2" />
                                </b-tooltip>
                            </b-form-group>

                            <!-- MANIFEST - CYLINDER -->
                            <b-form-group class="preop-input">
                                <b-form-input
                                    :id="'input-m-cylinder-' + this.side"
                                    :class="{
                                        ...showCylinderWarningRing(RefractionTypes.MANIFEST),
                                        ...inputClasses(
                                            `refractionInfo[${RefractionTypes.MANIFEST}].cylinder`
                                        ),
                                    }"
                                    v-model="
                                        $v.dataSet.refractionInfo[RefractionTypes.MANIFEST].cylinder
                                            .$model
                                    "
                                    :state="
                                        validateState(
                                            `refractionInfo[${RefractionTypes.MANIFEST}].cylinder`
                                        )
                                    "
                                    :formatter="formatInput"
                                    lazy
                                    :plaintext="!activeEditing"
                                    autocomplete="off"
                                    :disabled="disableRefractionTypeInput(RefractionTypes.MANIFEST)"
                                    @focus="onFocus('cylinder', RefractionTypes.MANIFEST)"
                                    @blur="
                                        checkWarning(
                                            'cylinder',
                                            () =>
                                                ($v.dataSet.refractionInfo[
                                                    RefractionTypes.MANIFEST
                                                ].cylinder.$model = null)
                                        )
                                    "
                                ></b-form-input>
                                <label
                                    :for="'input-m-cylinder-' + this.side"
                                    class="text-uppercase text-caption heavy text-gray-dark w-100 text-center text-nowrap"
                                >
                                    {{ t('preopreport_Cylinder') }}
                                </label>
                                <span class="input-units">D</span>
                                <b-tooltip
                                    custom-class="t-20"
                                    triggers="focus"
                                    :target="'input-m-cylinder-' + this.side"
                                    placement="bottom"
                                >
                                    {{ tooltipText('cylinder', ' D') }}
                                    <b-icon-check class="text-success ml-2" scale="2" />
                                </b-tooltip>
                            </b-form-group>

                            <!-- MANIFEST - AXIS -->
                            <b-form-group class="preop-input">
                                <b-form-input
                                    :id="'input-m-axis-' + this.side"
                                    v-model="
                                        $v.dataSet.refractionInfo[RefractionTypes.MANIFEST].axis
                                            .$model
                                    "
                                    :class="
                                        inputClasses(
                                            `refractionInfo[${RefractionTypes.MANIFEST}].axis`
                                        )
                                    "
                                    :state="
                                        validateState(
                                            `refractionInfo[${RefractionTypes.MANIFEST}].axis`
                                        )
                                    "
                                    :formatter="(val, $event) => formatInput(val, $event, [], 0, 3)"
                                    lazy
                                    :plaintext="!activeEditing"
                                    :disabled="disableRefractionTypeInput(RefractionTypes.MANIFEST)"
                                    autocomplete="off"
                                    @focus="onFocus('axis', RefractionTypes.MANIFEST, 0)"
                                    @blur="onBlur"
                                ></b-form-input>
                                <label
                                    :for="'input-m-axis-' + this.side"
                                    class="text-uppercase text-caption heavy text-gray-dark w-100 text-center text-nowrap"
                                >
                                    {{ t('preopreport_Axis') }}
                                </label>
                                <span class="input-units degree">&#176;</span>
                                <b-tooltip
                                    custom-class="t-20"
                                    triggers="focus"
                                    :target="'input-m-axis-' + this.side"
                                    placement="bottom"
                                >
                                    {{ tooltipText('axis', '\xB0', 0) }}
                                    <b-icon-check class="text-success ml-2" scale="2" />
                                </b-tooltip>
                            </b-form-group>

                            <!-- MANIFEST - BVD -->
                            <b-form-group class="preop-input">
                                <b-form-input
                                    :id="'input-m-bvd-' + this.side"
                                    v-model="
                                        $v.dataSet.refractionInfo[RefractionTypes.MANIFEST].bvd
                                            .$model
                                    "
                                    :class="
                                        inputClasses(
                                            `refractionInfo[${RefractionTypes.MANIFEST}].bvd`
                                        )
                                    "
                                    :state="
                                        validateState(
                                            `refractionInfo[${RefractionTypes.MANIFEST}].bvd`
                                        )
                                    "
                                    :formatter="(val, $event) => formatInput(val, $event, [])"
                                    lazy
                                    :plaintext="!activeEditing"
                                    :disabled="disableRefractionTypeInput(RefractionTypes.MANIFEST)"
                                    autocomplete="off"
                                    class="mm"
                                    @focus="onFocus('bvd', RefractionTypes.MANIFEST)"
                                    @blur="onBlur"
                                ></b-form-input>
                                <label
                                    :for="'input-m-bvd-' + this.side"
                                    class="text-uppercase text-caption heavy text-gray-dark w-100 text-center text-nowrap"
                                >
                                    {{ t('preopreport_BVD') }}
                                </label>
                                <span class="input-units mm">&nbsp;mm</span>
                                <b-tooltip
                                    custom-class="t-20"
                                    triggers="focus"
                                    :target="'input-m-bvd-' + this.side"
                                    placement="bottom"
                                >
                                    {{ tooltipText('bvd', ' mm') }}
                                    <b-icon-check class="text-success ml-2" scale="2" />
                                </b-tooltip>
                            </b-form-group>
                        </div>

                        <!-- CYCLOPLEGIC -->
                        <div class="mb-2" v-if="expanded && region === ZoneCodes.US">
                            <div class="text-caption heavy text-primary">
                                {{ t('preop_Refraction') }}:
                                <span
                                    :class="[
                                        activeRefractionType === RefractionTypes.CYCLO
                                            ? 'active-refraction-type'
                                            : 'text-gray',
                                    ]"
                                >
                                    {{ t('preop_Cycloplegic') }}</span
                                >
                            </div>
                        </div>

                        <div
                            class="d-flex preop-input-container"
                            :class="{'mb-2': expanded}"
                            v-if="
                                (activeRefractionType === RefractionTypes.CYCLO || expanded) &&
                                region === ZoneCodes.US
                            "
                        >
                            <b-form-radio
                                v-if="expanded"
                                :value="true"
                                :checked="dataSet.refractionInfo[RefractionTypes.CYCLO].active"
                                :disabled="disableChangingRefractionType"
                                @change="
                                    (value) => setActiveRefractionType(value, RefractionTypes.CYCLO)
                                "
                                class="mt-2"
                            ></b-form-radio>

                            <!-- CYCLO - SPHERE -->
                            <b-form-group class="preop-input">
                                <b-form-input
                                    :id="'input-cy-sphere-' + this.side"
                                    v-model="
                                        $v.dataSet.refractionInfo[RefractionTypes.CYCLO].sphere
                                            .$model
                                    "
                                    :class="
                                        inputClasses(
                                            `refractionInfo[${RefractionTypes.CYCLO}].sphere`
                                        )
                                    "
                                    :state="
                                        validateState(
                                            `refractionInfo[${RefractionTypes.CYCLO}].sphere`
                                        )
                                    "
                                    :formatter="formatInput"
                                    lazy
                                    :plaintext="!activeEditing"
                                    :disabled="disableRefractionTypeInput(RefractionTypes.CYCLO)"
                                    autocomplete="off"
                                    @focus="onFocus('sphere', RefractionTypes.CYCLO)"
                                    @blur="onBlur"
                                ></b-form-input>
                                <label
                                    :for="'input-cy-sphere-' + this.side"
                                    class="text-uppercase text-caption heavy text-gray-dark w-100 text-center text-nowrap"
                                >
                                    {{ t('preopreport_Sphere') }}
                                </label>
                                <span class="input-units">D</span>
                                <b-tooltip
                                    custom-class="t-20"
                                    triggers="focus"
                                    :target="'input-cy-sphere-' + this.side"
                                    placement="bottom"
                                >
                                    {{ tooltipText('sphere', ' D') }}
                                    <b-icon-check class="text-success ml-2" scale="2" />
                                </b-tooltip>
                            </b-form-group>

                            <!-- CYCLO - CYLINDER -->
                            <b-form-group class="preop-input">
                                <b-form-input
                                    :id="'input-cy-cylinder-' + this.side"
                                    :class="{
                                        ...showCylinderWarningRing(RefractionTypes.CYCLO),
                                        ...inputClasses(
                                            `refractionInfo[${RefractionTypes.CYCLO}].cylinder`
                                        ),
                                    }"
                                    v-model="
                                        $v.dataSet.refractionInfo[RefractionTypes.CYCLO].cylinder
                                            .$model
                                    "
                                    :state="
                                        validateState(
                                            `refractionInfo[${RefractionTypes.CYCLO}].cylinder`
                                        )
                                    "
                                    :formatter="formatInput"
                                    lazy
                                    :plaintext="!activeEditing"
                                    :disabled="disableRefractionTypeInput(RefractionTypes.CYCLO)"
                                    autocomplete="off"
                                    @focus="onFocus('cylinder', RefractionTypes.CYCLO)"
                                    @blur="
                                        checkWarning(
                                            'cylinder',
                                            () =>
                                                ($v.dataSet.refractionInfo[
                                                    RefractionTypes.CYCLO
                                                ].cylinder.$model = null)
                                        )
                                    "
                                ></b-form-input>
                                <label
                                    :for="'input-cy-cylinder-' + this.side"
                                    class="text-uppercase text-caption heavy text-gray-dark w-100 text-center text-nowrap"
                                >
                                    {{ t('preopreport_Cylinder') }}
                                </label>
                                <span class="input-units">D</span>
                                <b-tooltip
                                    custom-class="t-20"
                                    triggers="focus"
                                    :target="'input-cy-cylinder-' + this.side"
                                    placement="bottom"
                                >
                                    {{ tooltipText('cylinder', ' D') }}
                                    <b-icon-check class="text-success ml-2" scale="2" />
                                </b-tooltip>
                            </b-form-group>

                            <!-- CYCLO - AXIS -->
                            <b-form-group class="preop-input">
                                <b-form-input
                                    :id="'input-cy-axis-' + this.side"
                                    v-model="
                                        $v.dataSet.refractionInfo[RefractionTypes.CYCLO].axis.$model
                                    "
                                    :class="
                                        inputClasses(
                                            `refractionInfo[${RefractionTypes.CYCLO}].axis`
                                        )
                                    "
                                    :state="
                                        validateState(
                                            `refractionInfo[${RefractionTypes.CYCLO}].axis`
                                        )
                                    "
                                    :formatter="(val, $event) => formatInput(val, $event, [], 0, 3)"
                                    lazy
                                    :plaintext="!activeEditing"
                                    :disabled="disableRefractionTypeInput(RefractionTypes.CYCLO)"
                                    autocomplete="off"
                                    @focus="onFocus('axis', RefractionTypes.CYCLO, 0)"
                                    @blur="onBlur"
                                ></b-form-input>
                                <label
                                    :for="'input-cy-axis-' + this.side"
                                    class="text-uppercase text-caption heavy text-gray-dark w-100 text-center text-nowrap"
                                >
                                    {{ t('preopreport_Axis') }}
                                </label>
                                <span class="input-units degree">&#176;</span>
                                <b-tooltip
                                    custom-class="t-20"
                                    triggers="focus"
                                    :target="'input-cy-axis-' + this.side"
                                    placement="bottom"
                                >
                                    {{ tooltipText('axis', '\xB0', 0) }}
                                    <b-icon-check class="text-success ml-2" scale="2" />
                                </b-tooltip>
                            </b-form-group>

                            <!-- CYCLO - BVD -->
                            <b-form-group class="preop-input">
                                <b-form-input
                                    :id="'input-cy-bvd-' + this.side"
                                    v-model="
                                        $v.dataSet.refractionInfo[RefractionTypes.CYCLO].bvd.$model
                                    "
                                    :class="
                                        inputClasses(`refractionInfo[${RefractionTypes.CYCLO}].bvd`)
                                    "
                                    :state="
                                        validateState(
                                            `refractionInfo[${RefractionTypes.CYCLO}].bvd`
                                        )
                                    "
                                    :formatter="(val, $event) => formatInput(val, $event, [])"
                                    lazy
                                    :plaintext="!activeEditing"
                                    :disabled="disableRefractionTypeInput(RefractionTypes.CYCLO)"
                                    autocomplete="off"
                                    class="mm"
                                    @focus="onFocus('bvd', RefractionTypes.CYCLO)"
                                    @blur="onBlur"
                                ></b-form-input>
                                <label
                                    :for="'input-cy-bvd-' + this.side"
                                    class="text-uppercase text-caption heavy text-gray-dark w-100 text-center text-nowrap"
                                >
                                    {{ t('preopreport_BVD') }}
                                </label>
                                <span class="input-units mm">&nbsp;mm</span>
                                <b-tooltip
                                    custom-class="t-20"
                                    triggers="focus"
                                    :target="'input-cy-bvd-' + this.side"
                                    placement="bottom"
                                >
                                    {{ tooltipText('bvd', ' mm') }}
                                    <b-icon-check class="text-success ml-2" scale="2" />
                                </b-tooltip>
                            </b-form-group>
                        </div>

                        <!-- CL OVER REFRACTION -->
                        <div class="mb-2" v-if="expanded">
                            <div class="text-caption heavy text-primary">
                                {{ t('preop_Refraction') }}:
                                <span
                                    :class="[
                                        activeRefractionType === RefractionTypes.CL
                                            ? 'active-refraction-type'
                                            : 'text-gray',
                                    ]"
                                >
                                    {{ t('preop_OverRefraction') }}</span
                                >
                            </div>
                        </div>

                        <div
                            class="d-flex preop-input-container"
                            v-if="activeRefractionType === RefractionTypes.CL || expanded"
                        >
                            <b-form-radio
                                v-if="expanded"
                                :value="true"
                                :checked="dataSet.refractionInfo[RefractionTypes.CL].active"
                                :disabled="disableChangingRefractionType"
                                @change="
                                    (value) => setActiveRefractionType(value, RefractionTypes.CL)
                                "
                                class="mt-2"
                            ></b-form-radio>

                            <!-- CL - SPHERE -->
                            <b-form-group class="preop-input">
                                <b-form-input
                                    :id="'input-cl-sphere-' + this.side"
                                    v-model="
                                        $v.dataSet.refractionInfo[RefractionTypes.CL].sphere.$model
                                    "
                                    :class="
                                        inputClasses(`refractionInfo[${RefractionTypes.CL}].sphere`)
                                    "
                                    :state="
                                        validateState(
                                            `refractionInfo[${RefractionTypes.CL}].sphere`
                                        )
                                    "
                                    :formatter="formatInput"
                                    lazy
                                    :plaintext="!activeEditing"
                                    :disabled="disableRefractionTypeInput(RefractionTypes.CL)"
                                    autocomplete="off"
                                    @focus="onFocus('sphere', RefractionTypes.CL)"
                                    @blur="onBlur"
                                ></b-form-input>
                                <label
                                    :for="'input-cl-sphere-' + this.side"
                                    class="text-uppercase text-caption heavy text-gray-dark w-100 text-center text-nowrap"
                                >
                                    {{ t('preopreport_Sphere') }}
                                </label>
                                <span class="input-units">D</span>
                                <b-tooltip
                                    custom-class="t-20"
                                    triggers="focus"
                                    :target="'input-cl-sphere-' + this.side"
                                    placement="bottom"
                                >
                                    {{ tooltipText('sphere', ' D') }}
                                    <b-icon-check class="text-success ml-2" scale="2" />
                                </b-tooltip>
                            </b-form-group>

                            <!-- CL - CYLINDER -->
                            <b-form-group class="preop-input">
                                <b-form-input
                                    :id="'input-cl-cylinder-' + this.side"
                                    :class="{
                                        ...showCylinderWarningRing(RefractionTypes.CL),
                                        ...inputClasses(
                                            `refractionInfo[${RefractionTypes.CL}].cylinder`
                                        ),
                                    }"
                                    v-model="
                                        $v.dataSet.refractionInfo[RefractionTypes.CL].cylinder
                                            .$model
                                    "
                                    :state="
                                        validateState(
                                            `refractionInfo[${RefractionTypes.CL}].cylinder`
                                        )
                                    "
                                    :formatter="formatInput"
                                    lazy
                                    :plaintext="!activeEditing"
                                    :disabled="disableRefractionTypeInput(RefractionTypes.CL)"
                                    autocomplete="off"
                                    @focus="onFocus('cylinder', RefractionTypes.CL)"
                                    @blur="
                                        checkWarning(
                                            'cylinder',
                                            () =>
                                                ($v.dataSet.refractionInfo[
                                                    RefractionTypes.CL
                                                ].cylinder.$model = null)
                                        )
                                    "
                                ></b-form-input>
                                <label
                                    :for="'input-cl-cylinder-' + this.side"
                                    class="text-uppercase text-caption heavy text-gray-dark w-100 text-center text-nowrap"
                                >
                                    {{ t('preopreport_Cylinder') }}
                                </label>
                                <span class="input-units">D</span>
                                <b-tooltip
                                    custom-class="t-20"
                                    triggers="focus"
                                    :target="'input-cl-cylinder-' + this.side"
                                    placement="bottom"
                                >
                                    {{ tooltipText('cylinder', ' D') }}
                                    <b-icon-check class="text-success ml-2" scale="2" />
                                </b-tooltip>
                            </b-form-group>

                            <!-- CL - AXIS -->
                            <b-form-group class="preop-input">
                                <b-form-input
                                    :id="'input-cl-axis-' + this.side"
                                    v-model="
                                        $v.dataSet.refractionInfo[RefractionTypes.CL].axis.$model
                                    "
                                    :class="
                                        inputClasses(`refractionInfo[${RefractionTypes.CL}].axis`)
                                    "
                                    :state="
                                        validateState(`refractionInfo[${RefractionTypes.CL}].axis`)
                                    "
                                    :formatter="(val, $event) => formatInput(val, $event, [], 0, 3)"
                                    lazy
                                    :plaintext="!activeEditing"
                                    :disabled="disableRefractionTypeInput(RefractionTypes.CL)"
                                    autocomplete="off"
                                    @focus="onFocus('axis', RefractionTypes.CL, 0)"
                                    @blur="onBlur"
                                ></b-form-input>
                                <label
                                    :for="'input-cl-axis-' + this.side"
                                    class="text-uppercase text-caption heavy text-gray-dark w-100 text-center text-nowrap"
                                >
                                    {{ t('preopreport_Axis') }}
                                </label>
                                <span class="input-units degree">&#176;</span>
                                <b-tooltip
                                    custom-class="t-20"
                                    triggers="focus"
                                    :target="'input-cl-axis-' + this.side"
                                    placement="bottom"
                                >
                                    {{ tooltipText('axis', '\xB0', 0) }}
                                    <b-icon-check class="text-success ml-2" scale="2" />
                                </b-tooltip>
                            </b-form-group>

                            <!-- CL - BVD -->
                            <b-form-group class="preop-input">
                                <b-form-input
                                    :id="'input-cl-bvd-' + this.side"
                                    v-model="
                                        $v.dataSet.refractionInfo[RefractionTypes.CL].bvd.$model
                                    "
                                    :class="
                                        inputClasses(`refractionInfo[${RefractionTypes.CL}].bvd`)
                                    "
                                    :state="
                                        validateState(`refractionInfo[${RefractionTypes.CL}].bvd`)
                                    "
                                    :formatter="(val, $event) => formatInput(val, $event, [])"
                                    lazy
                                    :plaintext="!activeEditing"
                                    :disabled="disableRefractionTypeInput(RefractionTypes.CL)"
                                    autocomplete="off"
                                    class="mm"
                                    @focus="onFocus('bvd', RefractionTypes.CL)"
                                    @blur="onBlur"
                                ></b-form-input>
                                <label
                                    :for="'input-cl-bvd-' + this.side"
                                    class="text-uppercase text-caption heavy text-gray-dark w-100 text-center text-nowrap"
                                >
                                    {{ t('preopreport_BVD') }}
                                </label>
                                <span class="input-units mm">&nbsp;mm</span>
                                <b-tooltip
                                    custom-class="t-20"
                                    triggers="focus"
                                    :target="'input-cl-bvd-' + this.side"
                                    placement="bottom"
                                >
                                    {{ tooltipText('bvd', ' mm') }}
                                    <b-icon-check class="text-success ml-2" scale="2" />
                                </b-tooltip>
                            </b-form-group>

                            <!-- CL - CL -->
                            <b-form-group class="preop-input">
                                <b-form-input
                                    :id="'input-cl-cl-' + this.side"
                                    v-model="
                                        $v.dataSet.refractionInfo[RefractionTypes.CL].cl.$model
                                    "
                                    :class="
                                        inputClasses(`refractionInfo[${RefractionTypes.CL}].cl`)
                                    "
                                    :state="
                                        validateState(`refractionInfo[${RefractionTypes.CL}].cl`)
                                    "
                                    :formatter="formatInput"
                                    lazy
                                    :plaintext="!activeEditing"
                                    :disabled="disableRefractionTypeInput(RefractionTypes.CL)"
                                    autocomplete="off"
                                    @focus="onFocus('cl', RefractionTypes.CL)"
                                    @blur="onBlur"
                                ></b-form-input>
                                <label
                                    :for="'input-cl-cl-' + this.side"
                                    class="text-uppercase text-caption heavy text-gray-dark w-100 text-center text-nowrap"
                                >
                                    {{ t('preop_CL') }}
                                </label>
                                <span class="input-units">D</span>
                                <b-tooltip
                                    custom-class="t-20"
                                    triggers="focus"
                                    :target="'input-cl-cl-' + this.side"
                                    placement="bottom"
                                >
                                    {{ tooltipText('clsphere', ' D') }}
                                    <b-icon-check class="text-success ml-2" scale="2" />
                                </b-tooltip>
                            </b-form-group>
                        </div>
                    </b-card>

                    <!-- BIOMETRY -->
                    <b-card
                        body-class="pt-2"
                        class="biometry-card mb-4 mb-lg-3 py-2 py-lg-0"
                        v-if="!expanded"
                    >
                        <h6
                            class="heavy text-uppercase mb-3"
                            :class="[activeEditing ? 'text-primary' : 'text-gray-darker']"
                        >
                            {{ t('preop_Biometry') }}
                        </h6>

                        <div class="d-flex preop-input-container">
                            <!-- BIOMETRY - K1 -->
                            <b-form-group class="preop-input">
                                <b-form-input
                                    :id="'input-k1-' + this.side"
                                    v-model="$v.dataSet.k1.$model"
                                    :class="inputClasses('k1')"
                                    :state="validateState('k1')"
                                    :formatter="(val, $event) => formatInput(val, $event, [])"
                                    lazy
                                    :plaintext="!activeEditing"
                                    :disabled="!activeEditing"
                                    autocomplete="off"
                                    @focus="onFocus('k1')"
                                    @blur="onBlur"
                                ></b-form-input>
                                <label
                                    :for="'input-k1-' + this.side"
                                    class="text-uppercase text-caption heavy text-gray-dark w-100 text-center text-nowrap"
                                >
                                    {{ t('preopreport_K1') }}
                                </label>
                                <span class="input-units">D</span>
                                <b-tooltip
                                    custom-class="t-20"
                                    triggers="focus"
                                    :target="'input-k1-' + this.side"
                                    placement="bottom"
                                >
                                    {{ tooltipText('k1', ' D') }}
                                    <b-icon-check class="text-success ml-2" scale="2" />
                                </b-tooltip>
                            </b-form-group>

                            <!-- BIOMETRY - K1 AXIS -->
                            <b-form-group class="preop-input">
                                <b-form-input
                                    :id="'input-k1-axis-' + this.side"
                                    v-model="$v.dataSet.kAxis.$model"
                                    :class="inputClasses('kAxis')"
                                    :state="validateState('kAxis', checkAxisDistance)"
                                    :formatter="(val, $event) => formatInput(val, $event, [], 0, 3)"
                                    lazy
                                    :plaintext="!activeEditing"
                                    :disabled="!activeEditing"
                                    autocomplete="off"
                                    @focus="onFocus('kAxis', null, 0)"
                                    @blur="onBlur"
                                ></b-form-input>
                                <label
                                    :for="'input-k1-axis-' + this.side"
                                    class="text-uppercase text-caption heavy text-gray-dark w-100 text-center text-nowrap"
                                >
                                    {{ t('preopreport_K1_Axis') }}
                                </label>
                                <span class="input-units degree">&#176;</span>
                                <b-tooltip
                                    custom-class="t-20"
                                    triggers="focus"
                                    :target="'input-k1-axis-' + this.side"
                                    placement="bottom"
                                >
                                    {{ tooltipText('kaxis', '\xB0', 0) }}
                                    <b-icon-check class="text-success ml-2" scale="2" />
                                </b-tooltip>
                            </b-form-group>
                        </div>
                        <div class="d-flex preop-input-container">
                            <!-- BIOMETRY - K2 -->
                            <b-form-group class="preop-input">
                                <b-form-input
                                    :id="'input-k2-' + this.side"
                                    v-model="$v.dataSet.k2.$model"
                                    :class="inputClasses('k2')"
                                    :state="validateState('k2')"
                                    :formatter="(val, $event) => formatInput(val, $event, [])"
                                    lazy
                                    :plaintext="!activeEditing"
                                    :disabled="!activeEditing"
                                    autocomplete="off"
                                    @focus="onFocus('k2')"
                                    @blur="onBlur"
                                ></b-form-input>
                                <label
                                    :for="'input-k2-' + this.side"
                                    class="text-uppercase text-caption heavy text-gray-dark w-100 text-center text-nowrap"
                                >
                                    {{ t('preopreport_K2') }}
                                </label>
                                <span class="input-units">D</span>
                                <b-tooltip
                                    custom-class="t-20"
                                    triggers="focus"
                                    :target="'input-k2-' + this.side"
                                    placement="bottom"
                                >
                                    {{ tooltipText('k2', ' D') }}
                                    <b-icon-check class="text-success ml-2" scale="2" />
                                </b-tooltip>
                            </b-form-group>

                            <!-- BIOMETRY - K2 AXIS -->
                            <b-form-group class="preop-input">
                                <b-form-input
                                    :id="'input-k2-axis-' + this.side"
                                    v-model="$v.dataSet.k2Axis.$model"
                                    :class="inputClasses('k2Axis')"
                                    :state="validateState('k2Axis', checkAxisDistance)"
                                    :formatter="(val, $event) => formatInput(val, $event, [], 0, 3)"
                                    lazy
                                    :plaintext="!activeEditing"
                                    :disabled="!activeEditing"
                                    autocomplete="off"
                                    @focus="onFocus('k2Axis', null, 0)"
                                    @blur="onBlur"
                                ></b-form-input>
                                <label
                                    :for="'input-k2-axis-' + this.side"
                                    class="text-uppercase text-caption heavy text-gray-dark w-100 text-center text-nowrap"
                                >
                                    {{ t('preopreport_K2_Axis') }}
                                </label>
                                <span class="input-units degree">&#176;</span>
                                <b-tooltip
                                    custom-class="t-20"
                                    triggers="focus"
                                    :target="'input-k2-axis-' + this.side"
                                    placement="bottom"
                                >
                                    {{ tooltipText('k2axis', '\xB0', 0) }}
                                    <b-icon-check class="text-success ml-2" scale="2" />
                                </b-tooltip>
                            </b-form-group>

                            <!-- BIOMETRY - ACD -->
                            <b-form-group class="preop-input">
                                <b-form-input
                                    :id="'input-acd-' + this.side"
                                    :class="{
                                        ...inputClasses('acd'),
                                    }"
                                    v-model="$v.dataSet.acd.$model"
                                    :state="validateState('acd')"
                                    :formatter="(val, $event) => formatInput(val, $event, [])"
                                    lazy
                                    :plaintext="!activeEditing"
                                    :disabled="!activeEditing"
                                    autocomplete="off"
                                    class="mm"
                                    @focus="onFocus('acd')"
                                    @blur="onBlur"
                                ></b-form-input>
                                <label
                                    :for="'input-acd-' + this.side"
                                    class="text-uppercase text-caption heavy text-gray-dark w-100 text-center text-nowrap"
                                >
                                    {{ t(`preopreport_${region}_ACD`) }}
                                </label>
                                <span class="input-units mm">&nbsp;mm</span>
                                <b-tooltip
                                    custom-class="t-20"
                                    triggers="focus"
                                    :target="'input-acd-' + this.side"
                                    placement="bottom"
                                >
                                    {{ tooltipText('acd', ' mm') }}
                                    <b-icon-check class="text-success ml-2" scale="2" />
                                </b-tooltip>
                            </b-form-group>

                            <!-- BIOMETRY - CCT -->
                            <b-form-group class="preop-input">
                                <b-form-input
                                    :id="'input-cct-' + this.side"
                                    v-model="$v.dataSet.ct.$model"
                                    :class="inputClasses('ct')"
                                    :state="validateState('ct')"
                                    :formatter="(val, $event) => formatInput(val, $event, [], 0)"
                                    lazy
                                    :plaintext="!activeEditing"
                                    :disabled="!activeEditing"
                                    autocomplete="off"
                                    class="mm"
                                    @focus="onFocus('ct', null, 0)"
                                    @blur="onBlur"
                                ></b-form-input>
                                <label
                                    :for="'input-cct-' + this.side"
                                    class="text-uppercase text-caption heavy text-gray-dark w-100 text-center text-nowrap"
                                >
                                    {{ t('preopreport_CCT') }}
                                </label>
                                <span class="input-units mm">&nbsp;µm</span>
                                <b-tooltip
                                    custom-class="t-20"
                                    triggers="focus"
                                    :target="'input-cct-' + this.side"
                                    placement="bottom"
                                >
                                    {{ tooltipText('ct', ' µm', 0) }}
                                    <b-icon-check class="text-success ml-2" scale="2" />
                                </b-tooltip>
                            </b-form-group>

                            <!-- BIOMETRY - WW -->
                            <b-form-group class="preop-input">
                                <b-form-input
                                    :id="'input-ww-' + this.side"
                                    v-model="$v.dataSet.ww.$model"
                                    :class="inputClasses('ww')"
                                    :state="validateState('ww')"
                                    :formatter="(val, $event) => formatInput(val, $event, [])"
                                    lazy
                                    :plaintext="!activeEditing"
                                    :disabled="!activeEditing"
                                    autocomplete="off"
                                    class="mm"
                                    @focus="onFocus('ww')"
                                    @blur="onBlur"
                                ></b-form-input>
                                <label
                                    :for="'input-ww-' + this.side"
                                    class="text-uppercase text-caption heavy text-gray-dark w-100 text-center text-nowrap"
                                >
                                    {{ t('preopreport_WW') }}
                                </label>
                                <span class="input-units mm">&nbsp;mm</span>
                                <b-tooltip
                                    custom-class="t-20"
                                    triggers="focus"
                                    :target="'input-ww-' + this.side"
                                    placement="bottom"
                                >
                                    {{ tooltipText('ww', ' mm') }}
                                    <b-icon-check class="text-success ml-2" scale="2" />
                                </b-tooltip>
                            </b-form-group>
                        </div>
                    </b-card>
                </b-col>

                <b-col lg="5">
                    <b-card
                        :body-class="[
                            'pt-2',
                            {
                                'invalid-calculate-for': showCalculateForErrorMsg,
                            },
                        ]"
                        class="mb-4 mb-lg-2 py-2 py-lg-0"
                        style="min-height: 111px"
                    >
                        <h6
                            class="heavy text-uppercase"
                            :class="[activeEditing ? 'text-primary' : 'text-gray-darker']"
                        >
                            {{ t('preop_CalculateFor') }}
                        </h6>
                        <template v-if="activeEditing">
                            <div class="d-flex align-items-start justify-content-between flex-wrap">
                                <b-form-radio-group
                                    v-if="allowableLensModelOptions.length"
                                    id="lens-select"
                                    class="mb-2 mr-5 pr-5 mr-lg-2 pr-lg-0"
                                    buttons
                                    size="sm"
                                    :button-variant="
                                        cylinderWarning.isActive ? 'warning' : 'gray-dark'
                                    "
                                    :options="allowableLensModelOptions"
                                    :disabled="dataSet.asphericEdof && !showEdof('toric')"
                                    v-model="$v.dataSet.lensModelCode.$model"
                                    @change="
                                        checkWarning(
                                            'cylinder',
                                            () =>
                                                (dataSet.refractionInfo[
                                                    activeRefractionType
                                                ].cylinder = dataSet.lensModelCode =
                                                    null)
                                        )
                                    "
                                ></b-form-radio-group>
                                <div>
                                    <b-form-checkbox
                                        v-if="showEdof('spheric', 'toric')"
                                        switch
                                        class="calculate-for-checkbox asphericEdof mb-2"
                                        v-model="$v.dataSet.asphericEdof.$model"
                                        :disabled="
                                            !dataSet.lensModelCode ||
                                            (dataSet.lensModelCode == LensModelCodes.TORIC &&
                                                !showEdof('toric'))
                                        "
                                    >
                                        {{ t('preop_AsphericEDOF') }}
                                    </b-form-checkbox>
                                    <b-form-checkbox
                                        switch
                                        class="calculate-for-checkbox"
                                        v-model="$v.dataSet.monocular.$model"
                                        @input="oppositeEyeCleaning"
                                    >
                                        {{ t('preop_Monocular') }}
                                    </b-form-checkbox>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <b-form-group
                                class="mb-0 text-caption heavy"
                                style="font-size: .857rem; mb-0 pb-0"
                            >
                                <p class="text-uppercase">{{ dataSet.lensModelCode }}</p>
                                <p v-if="dataSet.asphericEdof" class="text-uppercase">
                                    {{ t('preop_AsphericEDOF') }}
                                </p>
                            </b-form-group>
                            <p v-if="showCalculateForErrorMsg" class="heavy text-gray-darker">
                                {{ t('preop_ErrorMessages_calculateFor') }}
                            </p>
                        </template>
                    </b-card>

                    <b-card body-class="pt-2 pb-0 pr-2" class="prompt-panel mb-3" v-if="prompt">
                        <h6 class="heavy text-primary text-uppercase">{{ t('preop_Prompt') }}</h6>

                        <p class="text-caption mb-2">
                            {{ prompt }}
                        </p>
                    </b-card>
                </b-col>
            </b-row>
        </div>
        <!-- Container for the eye diagram canvas, and the overlay and Edit button if the eye is currently not being edited. -->
        <div v-show="dataSet.status === Status.SAVED" class="canvas-container">
            <canvas :id="canvasId"> </canvas>
            <b-button
                v-if="!activeEditing && !oppositeEyeDataSet.monocular && canEdit"
                size="lg"
                class="font-weight-bolder text-uppercase p-1"
                variant="secondary"
                @click="setActiveEye(side)"
            >
                {{ t('preop_Edit') }} {{ side }}</b-button
            >
        </div>

        <TargetLensSelection
            v-if="dataSet.status !== Status.SAVED"
            :side="side"
            :preOpDataId="dataSet.preOpDataId"
            @cancel="onCancelTargetLensSelection"
        />

        <PreOpDataErrors
            :side="side"
            :validation="$v"
            :customToastErrors="customToastErrors"
            :refractionType="activeRefractionType"
        />
    </div>
</template>
<script>
import {mapGetters, mapState, mapMutations} from 'vuex';
import {get, isFunction, lowerCase, pick} from 'lodash';
import {required, requiredIf} from 'vuelidate/lib/validators';

import TargetLensSelection from '@/views/CalculatorDevice/components/TargetLensSelection.vue';
import PreOpDataErrors from './PreOpDataErrors';

import DiagramCanvas from '@/views/CalculatorDevice/classes/DiagramCanvas.js';
import {
    EyeSides,
    RefractionTypes,
    DefaultPreOpData,
    LensModelCodes,
    Status,
} from '@/store/modules/preopdata';
import {ZoneCodes} from '@/constants/zone';
import {PERMISSIONS, PERMISSIONS_VALUES} from '@/constants/permissions';
import {checkPermissions} from '@/utilities/permissions';
import {formatNumber, formatInput, parseToNumber} from '@/utilities/formatters';
import {WarningRanges as WARNING_RANGES} from '@/constants/preopdata';

const CUSTOM_ERROR_MESSAGE_KEYS = {
    AXES_NOT_90_APART: 'axes-not-90-apart',
};

export default {
    components: {
        TargetLensSelection,
        PreOpDataErrors,
    },

    props: {
        side: String,
    },

    data() {
        return {
            PERMISSIONS,
            PERMISSIONS_VALUES,
            EyeSides,
            RefractionTypes,
            Status,
            LensModelCodes,
            ZoneCodes,
            loaded: true,
            isExpanded: false,
            diagramObject: null,
            prompt: '',
            refractionTypeName: {
                1: 'Manifest',
                2: 'Cycloplegic',
                3: 'CL Over',
            },
        };
    },

    mounted() {
        this.initDiagram();
        this.tabControlInputsListeners(true);

        // If the window is smaller than XL breakpoint and this side is selected
        // in the `side` query param, scroll this side into view
        const XL_DIMENSION = 1200;
        if (window.innerWidth < XL_DIMENSION && this.$route.query.side === this.side) {
            this.scrollIntoView();
        }
    },

    beforeDestroy() {
        this.tabControlInputsListeners();
    },

    computed: {
        ...mapState({
            formState: (state) => state.preopdata.formState,
        }),
        ...mapGetters('user', ['currentUser']),
        ...mapGetters('permissions', ['permissions']),
        ...mapGetters('preopdata', ['preopdata', 'activeEye', 'validationRanges']),
        ...mapGetters('patient', ['currentPatient']),
        ...mapGetters({
            _activeRefractionType: 'preopdata/activeRefractionType',
            region: 'zone/currentZone',
        }),
        expanded: {
            get: function () {
                return this.isExpanded && this.dataSet.status === Status.SAVED;
            },
            set: function (newValue) {
                this.isExpanded = newValue;
            },
        },
        canEdit() {
            return this.checkPermissions(
                {
                    [this.PERMISSIONS.PATIENT_PREOP_DATA]: this.PERMISSIONS_VALUES.READ_WRITE,
                },
                this.permissions,
                this.currentUser.accessPermissions
            );
        },
        customToastErrors() {
            return [
                {
                    key: CUSTOM_ERROR_MESSAGE_KEYS.AXES_NOT_90_APART,
                    description: this.t('preop_ErrorMessages_K90Apart'),
                    visible: false,
                },
            ];
        },

        dataSet() {
            return get(this.preopdata, `preOpData[${this.side}]`, {});
        },

        oppositeEye() {
            return this.isOS ? EyeSides.OD : EyeSides.OS;
        },

        oppositeEyeDataSet() {
            return get(this.preopdata, `preOpData[${this.oppositeEye}]`, {});
        },

        diagramData() {
            return {
                cct: get(this.dataSet, 'ct', null),
                acd: get(this.dataSet, 'acd', null),
                ww: get(this.dataSet, 'ww', null),
                k1Axis: get(this.dataSet, 'kAxis', null),
                k2Axis: get(this.dataSet, 'k2Axis', null),
                refractionAxis: get(
                    this.dataSet,
                    `refractionInfo[${this.activeRefractionType}].axis`,
                    null
                ),
                activeEditing: this.activeEditing,
                validation: this.$v,
                warnings: {},
            };
        },

        /** True if this is OS side */
        isOS() {
            return this.side === EyeSides.OS;
        },

        /** True if this side is in active edit mode */
        activeEditing() {
            return (
                this.activeEye === this.side && this.dataSet.status === Status.SAVED && this.canEdit
            );
        },

        eyeIconStyles() {
            const thisEye = [this.activeEditing ? 'active' : 'inactive'];
            return thisEye;
        },

        /** The refraction type active for this eye */
        activeRefractionType() {
            return this._activeRefractionType[this.side];
        },

        disableChangingRefractionType() {
            const isSomeValueInvalid = Object.values(
                pick(this.$v.dataSet.refractionInfo[this.activeRefractionType], [
                    'sphere',
                    'cylinder',
                    'axis',
                    'bvd',
                    'cl',
                ])
            ).some((model) => !model.range);
            return isSomeValueInvalid;
        },

        cylinderWarning() {
            const {decimalSeparator} = this.currentUser;
            const cylinder = this.dataSet.refractionInfo[this.activeRefractionType].cylinder;
            const lensModelCode = this.dataSet.lensModelCode;
            if (
                cylinder &&
                !this.$v.dataSet.refractionInfo[this.activeRefractionType].cylinder.$invalid &&
                lensModelCode &&
                !this.$v.dataSet.lensModelCode.$invalid
            ) {
                if (
                    lensModelCode === LensModelCodes.SPHERIC &&
                    Math.abs(parseToNumber(cylinder)) > WARNING_RANGES.CYLINDER.MAX
                ) {
                    return {
                        isActive: true,
                        message: this.t('preop_WarningMessages_cylinderMax', {
                            max: formatNumber(WARNING_RANGES.CYLINDER.MAX, 1, decimalSeparator),
                        }),
                    };
                }
            }
            return {isActive: false};
        },

        canvasId() {
            return this.side + 'Canvas';
        },

        allowableLensModelOptions() {
            return Object.entries(LensModelCodes)
                .filter(([text]) =>
                    get(this.preopdata, `lensCertificationSummary[${lowerCase(text)}]`, false)
                )
                .map(([text, value]) => ({
                    text: LensModelCodes.SPHERIC === value ? value : text,
                    value,
                }));
        },

        showCalculateForErrorMsg() {
            return this.isAllValidExceptCalculateFor(this.side) && !this.activeEditing;
        },
    },

    methods: {
        checkPermissions,
        ...mapMutations({
            setPreOpDataStatus: 'preopdata/setPreOpDataStatus',
            setActiveEye: 'preopdata/setActiveEye',
            setFormState: 'preopdata/setFormState',
            showWarningModal: 'message/showWarningModal',
        }),

        /** Reset all preop data for this eye */
        reset() {
            const {decimalSeparator} = this.currentUser;
            this.$store.commit('preopdata/resetEye', {eyeSide: this.side, decimalSeparator});
            this.$v.$reset();
        },

        /** Scroll this eye side into view */
        scrollIntoView() {
            const el = this.$refs.container;
            if (el) {
                el.scrollIntoView();
            }
        },

        oppositeEyeCleaning(value) {
            if (!value) return;
            const {decimalSeparator} = this.currentUser;
            const oppositeEye = this.isOS ? EyeSides.OD : EyeSides.OS;
            const preOpDataIdOppositeEye = get(
                this.preopdata,
                `preOpData[${oppositeEye}].preOpDataId`
            );
            const state = this.formState[oppositeEye];
            if (!(preOpDataIdOppositeEye || state.touched)) return;
            this.showWarningModal({
                text: this.t('preop_Warning_monocularSwitching', {
                    side: this.side,
                    oppositeSide: oppositeEye,
                }),
                position: this.isOS ? 'right' : 'left',
                okTitle: this.t('continue'),
                ok: () => {
                    this.$store.commit('preopdata/resetEye', {
                        eyeSide: oppositeEye,
                        decimalSeparator,
                    });
                    /* this timeout call help a watcher which set form state too be executed first since
                    the line just above trigger a change in the model which the watch should attend,
                    but we need to ensure watch get call first than the piece of code bellow */
                    setTimeout(() => {
                        const formState = {touched: false, invalid: false, validations: this.$v};
                        this.setFormState({side: oppositeEye, formState});
                        this.$emit('reset-opposite-eye', oppositeEye);
                    });
                },
                cancel: () => (this.dataSet.monocular = false),
            });
        },

        /** Toggle the refraction card's expanded state */
        toggleExpanded() {
            // Track the inputs in which now we can make tabulation
            this.tabControlInputsListeners();
            this.expanded = !this.expanded;
            this.$nextTick(() => {
                this.tabControlInputsListeners(true);
            });
        },

        /** Update the active refraction type */
        setActiveRefractionType(value, refractionType) {
            if (value) {
                this.$v.dataSet.refractionInfo[refractionType].active.$touch();
                this.$store.commit('preopdata/setActiveRefractionType', {
                    eyeSide: this.side,
                    refractionType,
                });
            }
        },

        /** Formatter for numeric input fields */
        formatInput(value, event, allowedSymbols = ['-', '+'], decimalPlaces = 2, maxLength = 6) {
            const {decimalSeparator} = this.currentUser;
            return formatInput(
                value,
                event,
                allowedSymbols,
                decimalPlaces,
                maxLength,
                decimalSeparator
            );
        },

        /** Text for input tooltips displaying validation ranges */
        tooltipText(field, units, decimalPlaces = 2) {
            const {decimalSeparator} = this.currentUser;
            const allowPlusSign = ['sphere', 'cylinder', 'clsphere'].includes(field);
            if (field in this.validationRanges) {
                const range = this.validationRanges[field];
                const plusSign = allowPlusSign && range.max > 0 ? '+' : '';
                const min = formatNumber(range.min, decimalPlaces, decimalSeparator);
                const max = formatNumber(range.max, decimalPlaces, decimalSeparator);
                return `${min}${units} ... ${plusSign}${max}${units}`;
            }
            return '';
        },

        /** Check the acd, cylinder fields, and display a warning modal if necessary */
        checkWarning(type, cancel) {
            this.onBlur();
            const warningType = `${type}Warning`;
            if (this[warningType].isActive) {
                const text = this[warningType].message;
                const position = this.isOS ? 'right' : 'left';
                this.showWarningModal({
                    text,
                    position,
                    cancel,
                    okTitle: this.t('continue'),
                });
            }
        },

        showCylinderWarningRing(refractionType) {
            const active =
                this.dataSet.refractionInfo[refractionType].active &&
                !this.$v.dataSet.refractionInfo[refractionType].cylinder.$invalid &&
                this.cylinderWarning.isActive;
            return {
                'is-warning': active,
            };
        },

        /** Check the K1/K2 Axes are 90° apart */
        // If K1/K2 Axes are not 90° apart trigger toast and highlighted fields in red
        checkAxisDistance() {
            const k1Axis = get(this.dataSet, 'kAxis', null);
            const k2Axis = get(this.dataSet, 'k2Axis', null);
            const isInvalid =
                !!k1Axis && !!k2Axis && Math.abs(Number(k1Axis) - Number(k2Axis)) != 90;
            const toastError = this.customToastErrors.find(
                (error) => error.key === CUSTOM_ERROR_MESSAGE_KEYS.AXES_NOT_90_APART
            );
            toastError.visible = isInvalid;
            return !isInvalid;
        },

        onFocus(field, refractionType = null, decimalPlaces = 2) {
            const {decimalSeparator} = this.currentUser;
            const rangeKey = field === 'cl' ? 'clsphere' : field.toLowerCase();
            const allowPlusSign = ['sphere', 'cylinder', 'clsphere'].includes(rangeKey);
            const range = this.validationRanges[rangeKey];
            const plusSign = allowPlusSign && range.max > 0 ? '+' : '';
            const config = {
                field: field.toUpperCase(),
                min: formatNumber(range.min, decimalPlaces, decimalSeparator),
                max: plusSign + formatNumber(range.max, decimalPlaces, decimalSeparator),
                dec: decimalSeparator,
                refractionType: this.refractionTypeName[refractionType],
            };

            switch (refractionType) {
                case RefractionTypes.CL:
                    field = `refraction_type_${refractionType}_${field}`;
                    break;
            }

            var fieldName = `preop_PromptMessage_${this.region}_${field}`;
            this.prompt = this.t(fieldName, config);
        },

        onBlur() {
            this.prompt = null;
        },

        onCancelTargetLensSelection(shouldTriggerSave) {
            this.setPreOpDataStatus({status: Status.SAVED, sides: [this.side]});
            this.setActiveEye(this.side);
            /* 
                When mixed astigmatism warning is present in calculator we have the chance to returning 
                back to a saved preopdata status, we need to ensure this change is peristed into database 
            */
            if (shouldTriggerSave) {
                const {decimalSeparator} = this.currentUser;
                this.blockingRequest('preopdata/saveOrUpdatePreopData', decimalSeparator);
            }
        },

        isAllValidExceptCalculateFor(eye) {
            const {validations} = this.formState[eye];
            const invalid = Object.entries(validations.dataSet || {})
                .filter(([key]) => !key.includes('$') && key != 'lensModelCode')
                .some(([_, value]) => value.$invalid);
            return !invalid && get(validations, 'dataSet.lensModelCode.$invalid', false);
        },

        displayPrompt(model) {
            this.promptModel = model;
        },

        /* Update the eyeball diagram using the latest values. */
        initDiagram() {
            /**
             * A fixed size is defined in which all the components that make up the diagram look good.
             * Canvas automatically scales rendered components to fit the screen.
             */
            const canvas = document.getElementById(this.canvasId);
            canvas.width = 910;
            canvas.height = 510;
            const context = canvas.getContext('2d');

            const canvasData = {
                canvas,
                context,
                side: this.side,
                bgImage: require(`@/assets/preop-data/DiagramComponents/EYE-BG-${this.side}.svg`),
            };

            this.diagramObject = new DiagramCanvas(canvasData, this.diagramData);
        },

        validateState(field, validator) {
            const isValid = isFunction(validator) ? validator() : true;
            const {$error, $model, $dirty} = get(this.$v, `dataSet.${field}`, {});
            return $model || $dirty ? !$error && isValid : null;
        },

        disableRefractionTypeInput(refractionType) {
            return (
                !this.activeEditing ||
                (this.expanded && this.activeRefractionType !== refractionType)
            );
        },

        inputElements() {
            return Array.from(this.$el.querySelectorAll('.preop-input input'));
        },

        tabControlInputsListeners(register) {
            if (register) {
                this.inputElements().forEach((input) =>
                    input.addEventListener('keydown', this.goNextInput)
                );
            } else {
                this.inputElements().forEach((input) =>
                    input.removeEventListener('keydown', this.goNextInput)
                );
            }
        },

        goNextInput(event) {
            // key code for enter, tab, shift
            const isTabulationClick = [9, 13].includes(event.keyCode);
            if (isTabulationClick || (event.shiftKey && isTabulationClick)) {
                event.preventDefault();
                const inputElements = this.inputElements().filter((input) => !input.disabled);
                let index = inputElements.findIndex((input) => input === event.target);
                // if shiftKey pressed go before input otherwise go next input
                event.shiftKey ? index-- : index++;
                //if last, jump to first one
                if (index > inputElements.length - 1) index = 0;
                //if first, jump to last one
                else if (index < 0) index = inputElements.length - 1;

                inputElements[index].focus();
            }
        },

        showEdof() {
            //upgraded to use an array of lens types
            //allows for toric & spheric edof check
            const names = [...arguments];
            return names.some((name) =>
                get(this.preopdata, `lensCertificationSummary.${name}Edof`, false)
            );
        },

        inputClasses(path) {
            const {decimalSeparator} = this.currentUser;
            const hasChanged =
                JSON.stringify(DefaultPreOpData(this.side, decimalSeparator)) !==
                JSON.stringify(this.dataSet);
            return {
                'is-invalid':
                    hasChanged &&
                    !this.activeEditing &&
                    get(this.$v.dataSet, `${path}.$invalid`, false),
            };
        },
    },

    watch: {
        dataSet: {
            handler() {
                this.diagramObject.drawDiagram(this.diagramData);
            },
            deep: true,
        },

        $v: {
            handler({$anyDirty, $invalid}) {
                this.$nextTick(() => {
                    const hasCustomErrors = this.customToastErrors.some((error) => error.visible);
                    const formState = {
                        touched: $anyDirty,
                        invalid: $invalid || hasCustomErrors,
                        validations: this.$v,
                    };
                    this.setFormState({side: this.side, formState});
                });
            },
            deep: true,
            immediate: true,
        },

        activeEye() {
            this.expanded = false;
            this.diagramObject.drawDiagram(this.diagramData);
        },
    },

    validations() {
        const {decimalSeparator} = this.currentUser;
        const rangeIf = (key, validateIf) => (value) => {
            let valid = true;
            const rangeKey = key.toLowerCase();

            //If there is no value, the range is not validated
            if (!value) {
                return valid;
            }

            //If the ranges have not been obtained or the key does not exist in the ranges that are, the range is not validated
            if (!(this.validationRanges && rangeKey in this.validationRanges)) {
                return valid;
            }

            // If the function was defined but it says that it cannot be validated, the range is not validated
            if (isFunction(validateIf) && !validateIf(this.dataSet)) {
                return valid;
            }

            value = value?.replace(decimalSeparator, '.');
            const ranges = this.validationRanges[rangeKey];
            if (ranges.max !== null) {
                valid = valid && value <= ranges.max;
            }
            if (ranges.min !== null) {
                valid = valid && value >= ranges.min;
            }
            return valid;
        };

        const getValidations = (required, range) => {
            return {
                required,
                range,
            };
        };

        const validations = {
            refractionInfo: Object.values(this.RefractionTypes).reduce(
                (acc, currentType) => ({...acc, [currentType]: {}}),
                {}
            ),
        };

        Object.keys(DefaultPreOpData())
            .filter((key) => !['readOnly', 'refractionInfo', 'opEye'].includes(key))
            .forEach((key) => {
                validations[key] = getValidations(required, rangeIf(key));
            });

        ['axis', 'bvd', 'cylinder', 'sphere', 'active'].forEach((key) => {
            Object.values(this.RefractionTypes).forEach((refractionType) => {
                validations.refractionInfo[refractionType][key] = getValidations(
                    requiredIf((dataSet) => dataSet.active),
                    rangeIf(key, (dataSet) => dataSet.refractionInfo[refractionType].active)
                );
            });
        });

        /** The only type of refraction that includes this property is the CL,
         * so it is a specific case and must be handled separately adding the validations
         * specifically to this object and not to all types of refractions.*/
        validations.refractionInfo[this.RefractionTypes.CL]['cl'] = getValidations(
            requiredIf((dataSet) => dataSet.active),
            rangeIf('clsphere', (dataSet) => dataSet.refractionInfo[this.RefractionTypes.CL].active)
        );

        return {
            dataSet: validations,
        };
    },
};
</script>

<style lang="scss">
@import '@/assets/css/_variables';

.eye-side-container {
    scroll-margin-top: 60px;
}

.calculate-for-checkbox.asphericEdof {
    flex-basis: 100%;
}

.preop-form-container {
    min-height: 301px;
}

.canvas-container {
    position: relative;

    button {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: 2rem;
    }

    canvas {
        width: 100%;
        border-radius: 8px;
    }
}

.monocular-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: -2px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: whitesmoke;
    opacity: 0.8;
    z-index: 2;
    p {
        position: absolute;
        top: 15%;
        left: 0;
        right: 0;
        text-align: center;
        text-transform: uppercase;
        font-size: 1.7rem;
        font-weight: bolder;
        color: #616469;
    }
}

.monocular-label {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    margin: auto;
    bottom: 72%;
}
.form-control-plaintext.is-invalid {
    border: 1px solid #f03738;
    border-radius: 8px;
}
.form-control-plaintext.is-warning {
    border-width: 1px 1px;
}
.prompt-panel p {
    max-height: 122px;
    overflow-y: auto;
}

.calculate-for-checkbox .custom-control-label {
    padding-top: 2px;
}

/* custom scrollbar */
.prompt-panel p::-webkit-scrollbar {
    width: 20px;
}

.prompt-panel p::-webkit-scrollbar-track {
    background-color: transparent;
}

.prompt-panel p::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
}

.prompt-panel p::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
}

.min-h-24px {
    min-height: 24px;
}

#leftColContainer {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 150px;
}

.t-20 {
    top: 20px !important; /* bootstrap override without it */
}

.flex-basis-100 {
    flex-basis: 100%;
}
.invalid-calculate-for {
    border: 1px solid;
    border-radius: 6px;
    border-color: #f03738;
}

#lens-select {
    label.focus {
        box-shadow: none;
    }
}

@media (max-width: 1507px) and (min-width: 1292px) {
    .preop-form-container {
        min-height: 370px;
    }
}

@media (max-width: 1293px) and (min-width: 1199px) {
    .preop-form-container {
        min-height: 432px;
    }
}
</style>
